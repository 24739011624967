import { useState, useEffect } from "react";
import axios from "axios";
import { KitInfo, TeamInfo, LeagueInfo } from "../models/GtkModels";
const API_TOKEN = process.env.REACT_APP_API_TOKEN; 
const API_URL = process.env.REACT_APP_KIT_IMAGE_ENDPOINT_DEV || "https://localhost:7031"; 

const axiosInstance = axios.create({
  headers: {
    Authorization: `Bearer ${API_TOKEN}`, // Add the Bearer token
  },
});
export const useKitImage = () => {
  const [kitImage, setKitImage] = useState<KitInfo | null>(null);

  const fetchKitImage = async () => {
    try {
      const response = await axiosInstance.get<KitInfo>(
        `${API_URL}/api/KitImage/DailyKit`
      );

      setKitImage({ ...response.data, season: response.data.season.slice(-2) });
    } catch (error) {
      console.error("Error fetching the kit image:", error);
    }
  };

  useEffect(() => {
    fetchKitImage(); // Fetch the initial kit image when the component is mounted
  }, []);

  return { kitImage, fetchKitImage };
};

export const UseKitFromLeague = (selectedLeagueId: number | null) => {
  const [kitImage, setKitImage] = useState<KitInfo | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchKitImage = async () => {
    if (selectedLeagueId === null) {
      return; // Do nothing if no league is selected
    }

    setIsLoading(true); // Set loading state to true
    try {
      const url = `${API_URL}/api/KitImage/KitFromLeague/${selectedLeagueId}`;

      const response = await axiosInstance.get<KitInfo>(url);

      setKitImage({ ...response.data, season: response.data.season.slice(-2) }); // Set kit image
    } catch (error) {
      console.error("Error fetching the kit image:", error);
    } finally {
      setIsLoading(false); // Set loading state to false after fetching
    }
  };

  useEffect(() => {
    if (selectedLeagueId !== null) {
      fetchKitImage();
    }
  }, [selectedLeagueId]);

  return { kitImage, fetchKitImage, isLoading };
};

export const useLeagues = () => {
  const [leagues, setLeagues] = useState<LeagueInfo[]>([]);

  useEffect(() => {
    const fetchLeagues = async () => {
      try {
        const response = await axiosInstance.get<LeagueInfo[]>(
          `${API_URL}/api/League/Leagues`
        );
        setLeagues(response.data);
      } catch (error) {
        console.error("Error fetching leagues:", error);
      }
    };

    fetchLeagues();
  }, []);

  return leagues;
};


export const useTeams = (selectedLeagueId: number | null) => {
  const [teams, setTeams] = useState<TeamInfo[]>([]);

  useEffect(() => {
    if (selectedLeagueId !== null) {
      const fetchTeams = async () => {
        try {
          const response = await axiosInstance.get<TeamInfo[]>(
            `${API_URL}/api/League/Teams/${selectedLeagueId}`
          );
          setTeams(response.data);
        } catch (error) {
          console.error("Error fetching teams:", error);
        }
      };

      fetchTeams();
    }
  }, [selectedLeagueId]);

  return teams;
};